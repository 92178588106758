<template>
<b-row>
    <b-col cols="12">
    <p>Please record the value that you generate for later use as you will not see it from this dashboard on subsequent visits.</p>
    </b-col>
   
    <b-col cols="12">
        <b-card>
            <b-form @submit.prevent>
                <b-row>
                    <b-col md="12">
                        <b-form-group>
                            <validation-provider
                            #default="{ errors }"
                            name="Password"
                            vid="Password"
                            rules="required|password"
                            >
                            <b-form-input
                                v-model="PasswordValue"
                                type="password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Your Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                    <!-- reset and submit -->
                    <b-col cols="12">
                         <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
        >
          Generate Token
        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </b-card>
    </b-col>
    
</b-row>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BCard,
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      PasswordValue: '',
      passwordCon: '',
      required,
      password,
      email,
      confirmed,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },
}

</script>
